import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { isEmpty } from "lodash";
import { Link, NavLink } from "react-router-dom";
import { useGetHotelsQuery } from "../../redux/features/api/hotelsApi";
import { setHotel, hotelInfo, userName } from "../../redux/features/currentSlice";
import { useLogoutMutation } from "../../redux/features/api/authApi";
import { NavBar, Popover, Picker, List, Popup, Checkbox } from "antd-mobile/es";
import { RightOutline } from "antd-mobile-icons";
import IconOpenMenu from "./../../img/menu.svg";
import IconCloseMenu from "./../../img/close.svg";
import IconFilter from "./../../img/filter.svg";
import { ReactComponent as IconFullLogo } from "./../../img/full_logo.svg";
import { ReactComponent as IconCurrency } from "./../../img/currency.svg";
import { ReactComponent as IconRu } from "./../../img/ru.svg";
import { ReactComponent as IconEn } from "./../../img/en.svg";
import "./style.css";

const Menu = (props) => {
  const { t, i18n } = useTranslation();
  const name = useSelector(userName);
  const { hotels } = useGetHotelsQuery(undefined, {
    selectFromResult: ({ data }) => ({ hotels: data})
  });

  const currentHotel = useSelector(hotelInfo);
  const dispatch = useDispatch();

  const [ isOpen, setIsOpen ] = useState(false);
  const [ showFilter, setShowFilter ] = useState(false);
  const [ pickerData, setPickerData ] = useState(null);
  const [ doLogout ] = useLogoutMutation();

  const selectLanguage = id => {
    if (id !== i18n.language) {
      i18n.changeLanguage(id);
      moment.locale(id === "en" ? "en-gb" : id);

      if (id === "ru") {
        moment.updateLocale("ru", {
          weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"]
        });
        moment.updateLocale("ru", {
          months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]
        });
       }
    }
  };

  const sidebar = (<List>
    <List.Item
      key={"1item"}
      className="menu-user-wrapper"
      prefix={<div className="menu-dot-wrapper"><span className="menu-dot"/>{name}</div>}
      extra={<div className="menu-user-inner">
        <button onClick={() => setIsOpen(false)} className="invisible-button">
          <img
            className="menu-icon"
            src={IconCloseMenu}
            alt={"Close menu icon"}/>
        </button>
      </div>} />

    {!isEmpty(currentHotel) && <div className="menu-hotel">
      <List.Item
        arrow={<RightOutline />}
        onClick={() => setPickerData({
          title: t("currHotel"),
          value: currentHotel.ind,
          columns: hotels.map((hotel, ind) => ({
            value: ind,
            label: hotel.title
          })),
          onConfirm: (v) => {
            dispatch(setHotel(hotels[v[0]]));
            localStorage.setItem("hotelId", hotels[v[0]]?.id);
            setIsOpen(false);
          }
        })}>
        {currentHotel.title}
      </List.Item>
    </div>}

    <List.Item key={"2item"}>
      <IconFullLogo width="100px" />
    </List.Item>

    <NavLink
      key={"3item"}
      to="/"
      isActive={(_, location) => location.pathname === "/"}
      exact
      activeClassName="active"
      className="nav-link">
      <List.Item
        key={"in3item"}
        prefix={
          <svg height="20px" viewBox="0 0 512 512" width="20px">
            <path d="m197.332031 170.667969h-160c-20.585937 0-37.332031-16.746094-37.332031-37.335938v-96c0-20.585937 16.746094-37.332031 37.332031-37.332031h160c20.589844 0 37.335938 16.746094 37.335938 37.332031v96c0 20.589844-16.746094 37.335938-37.335938 37.335938zm-160-138.667969c-2.941406 0-5.332031 2.390625-5.332031 5.332031v96c0 2.945313 2.390625 5.335938 5.332031 5.335938h160c2.945313 0 5.335938-2.390625 5.335938-5.335938v-96c0-2.941406-2.390625-5.332031-5.335938-5.332031zm0 0"/>
            <path d="m197.332031 512h-160c-20.585937 0-37.332031-16.746094-37.332031-37.332031v-224c0-20.589844 16.746094-37.335938 37.332031-37.335938h160c20.589844 0 37.335938 16.746094 37.335938 37.335938v224c0 20.585937-16.746094 37.332031-37.335938 37.332031zm-160-266.667969c-2.941406 0-5.332031 2.390625-5.332031 5.335938v224c0 2.941406 2.390625 5.332031 5.332031 5.332031h160c2.945313 0 5.335938-2.390625 5.335938-5.332031v-224c0-2.945313-2.390625-5.335938-5.335938-5.335938zm0 0"/>
            <path d="m474.667969 512h-160c-20.589844 0-37.335938-16.746094-37.335938-37.332031v-96c0-20.589844 16.746094-37.335938 37.335938-37.335938h160c20.585937 0 37.332031 16.746094 37.332031 37.335938v96c0 20.585937-16.746094 37.332031-37.332031 37.332031zm-160-138.667969c-2.945313 0-5.335938 2.390625-5.335938 5.335938v96c0 2.941406 2.390625 5.332031 5.335938 5.332031h160c2.941406 0 5.332031-2.390625 5.332031-5.332031v-96c0-2.945313-2.390625-5.335938-5.332031-5.335938zm0 0"/>
            <path d="m474.667969 298.667969h-160c-20.589844 0-37.335938-16.746094-37.335938-37.335938v-224c0-20.585937 16.746094-37.332031 37.335938-37.332031h160c20.585937 0 37.332031 16.746094 37.332031 37.332031v224c0 20.589844-16.746094 37.335938-37.332031 37.335938zm-160-266.667969c-2.945313 0-5.335938 2.390625-5.335938 5.332031v224c0 2.945313 2.390625 5.335938 5.335938 5.335938h160c2.941406 0 5.332031-2.390625 5.332031-5.335938v-224c0-2.941406-2.390625-5.332031-5.332031-5.332031zm0 0"/>
          </svg>}
        arrow={<RightOutline />}>
        <span>{t("headers.dashboard")}</span>
      </List.Item>
      </NavLink>

      <NavLink
        key={"4item"}
        to="/calendar"
        isActive={(_, location) => location.pathname === "/calendar"}
        exact
        activeClassName="active"
        className="nav-link">

      <List.Item
        key={"in4item"}
        prefix={<svg x="0px" y="0px" width="20px" viewBox="0 0 512 512">
        <g><g><path d="M452,40h-24V0h-40v40H124V0H84v40H60C26.916,40,0,66.916,0,100v352c0,33.084,26.916,60,60,60h392c33.084,0,60-26.916,60-60V100C512,66.916,485.084,40,452,40z M472,452c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V188h432V452z M472,148H40v-48c0-11.028,8.972-20,20-20h24v40h40V80h264v40h40V80h24c11.028,0,20,8.972,20,20V148z"/></g></g>
        <g><g><rect x="76" y="230" width="40" height="40"/></g></g>
        <g><g><rect x="156" y="230" width="40" height="40"/></g></g>
        <g><g><rect x="236" y="230" width="40" height="40"/></g></g>
        <g><g><rect x="316" y="230" width="40" height="40"/></g></g>
        <g><g><rect x="396" y="230" width="40" height="40"/></g>
        </g><g><g><rect x="76" y="310" width="40" height="40"/></g></g>
        <g><g><rect x="156" y="310" width="40" height="40"/></g></g>
        <g><g><rect x="236" y="310" width="40" height="40"/></g></g>
        <g><g><rect x="316" y="310" width="40" height="40"/></g></g>
        <g><g><rect x="76" y="390" width="40" height="40"/></g></g>
        <g><g><rect x="156" y="390" width="40" height="40"/></g></g>
        <g><g><rect x="236" y="390" width="40" height="40"/></g></g>
        <g><g><rect x="316" y="390" width="40" height="40"/></g></g>
        <g><g><rect x="396" y="310" width="40" height="40"/></g></g>
        </svg>}
        arrow={<RightOutline />}><span>{t("headers.calendar")}</span>
      </List.Item>
    </NavLink>

    <a className="nav-link" onClick={(evt) => {
      evt.preventDefault();
      document.cookie = "IGNORE_MOBILE_REDIRECT=true";
      window.location.href="https://app.hotellab.io";
    }}>
    <List.Item
      key={"5item"}
      prefix={
        <svg viewBox="0 0 24 24" width="20px"> <g clipRule="evenodd" fillRule="evenodd">
        <path d="m11.3353 17.3292c.3705.1852.5207.6357.3354 1.0062l-1.5 3c-.1852.3705-.63571.5207-1.00619.3354-.37048-.1852-.52065-.6357-.33541-1.0062l1.5-3c.1852-.3705.6357-.5207 1.0062-.3354z" fill="#486472"/>
        <path d="m14.8353 21.6708c.3705-.1852.5207-.6357.3354-1.0062l-1.5-3c-.1852-.3705-.6357-.5207-1.0062-.3354-.3705.1852-.5206.6357-.3354 1.0062l1.5 3c.1852.3705.6357.5207 1.0062.3354z" fill="#486472"/>
        <path d="m6.25 21c0-.4142.33579-.75.75-.75h10c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-10c-.41421 0-.75-.3358-.75-.75z" fill="#486472"/></g>
        <path className="init" d="m4 3.25c-1.51878 0-2.75 1.23122-2.75 2.75v10c0 1.5188 1.23122 2.75 2.75 2.75h16c1.5188 0 2.75-1.2312 2.75-2.75v-10c0-1.51878-1.2312-2.75-2.75-2.75z" fill="#fafafa"/>
        <path clipRule="evenodd" d="m1.25 6c0-1.51878 1.23122-2.75 2.75-2.75h16c1.5188 0 2.75 1.23122 2.75 2.75v10c0 1.5188-1.2312 2.75-2.75 2.75h-16c-1.51878 0-2.75-1.2312-2.75-2.75zm2.75-1.25c-.69036 0-1.25.55964-1.25 1.25v10c0 .6904.55964 1.25 1.25 1.25h16c.6904 0 1.25-.5596 1.25-1.25v-10c0-.69036-.5596-1.25-1.25-1.25z" fill="#607d8b" fillRule="evenodd"/>
        <path clipRule="evenodd" d="m4 3.25c-1.51878 0-2.75 1.23122-2.75 2.75v10c0 1.5188 1.23122 2.75 2.75 2.75h5.78647l-.75 1.5h-2.03647c-.41421 0-.75.3358-.75.75s.33579.75.75.75h2.48376c.01045.0002.0209.0002.03132 0h4.96982c.0104.0002.0209.0002.0313 0h2.4838c.4142 0 .75-.3358.75-.75s-.3358-.75-.75-.75h-2.0365l-.75-1.5h5.7865c1.5188 0 2.75-1.2312 2.75-2.75v-10c0-1.51878-1.2312-2.75-2.75-2.75zm9.2865 17-.75-1.5h-1.073l-.75 1.5zm-.3027-3c.0104-.0002.0209-.0002.0313 0h6.9849c.6904 0 1.25-.5596 1.25-1.25v-10c0-.69036-.5596-1.25-1.25-1.25h-16c-.69036 0-1.25.55964-1.25 1.25v10c0 .6904.55964 1.25 1.25 1.25h6.9849c.0104-.0002.0209-.0002.0313 0z" fill="#000" fillRule="evenodd"/>
        </svg>
      }
      arrow={<RightOutline />}>{t("headers.fullVers")}</List.Item></a>

  <List.Item
    key={"6item"}
    arrow={<RightOutline />}
    extra={localStorage.getItem("i18nextLng").indexOf("ru") !== -1 ? "Русский" : "English"}
    onClick={() => setPickerData({
      value: localStorage.getItem("i18nextLng").indexOf("ru") !== -1 ? "ru" : "en",
      columns: [{
        value: "en",
        label: "English",
      },
      {
        value: "ru",
        label: "Русский",
      }],
      onConfirm: (v) => selectLanguage(v[0])
    })}>
      {localStorage.getItem("i18nextLng").indexOf("ru") !== -1 ? <IconRu /> : <IconEn />}
  </List.Item>

  {pickerData && <Picker
    title={pickerData.title}
    value={[ pickerData.value ]}
    columns={[ pickerData.columns ]}
    visible={pickerData}
    onCancel={() => setPickerData(null)}
    onConfirm={(v) => {
      pickerData.onConfirm(v);
      setPickerData(null)
    }} />}


  <Link className="nav-link" to="/" onClick={() => doLogout().unwrap()}>
    <List.Item key={"7item"}
      prefix={
        <svg x="0px" y="0px" width="20px" viewBox="0 0 512 512">
        <g><g><path d="M255.15,468.625H63.787c-11.737,0-21.262-9.526-21.262-21.262V64.638c0-11.737,9.526-21.262,21.262-21.262H255.15
        			c11.758,0,21.262-9.504,21.262-21.262S266.908,0.85,255.15,0.85H63.787C28.619,0.85,0,29.47,0,64.638v382.724
        			c0,35.168,28.619,63.787,63.787,63.787H255.15c11.758,0,21.262-9.504,21.262-21.262
        			C276.412,478.129,266.908,468.625,255.15,468.625z"/></g></g>
        <g><g><path d="M505.664,240.861L376.388,113.286c-8.335-8.25-21.815-8.143-30.065,0.213s-8.165,21.815,0.213,30.065l92.385,91.173
        			H191.362c-11.758,0-21.262,9.504-21.262,21.262c0,11.758,9.504,21.263,21.262,21.263h247.559l-92.385,91.173
        			c-8.377,8.25-8.441,21.709-0.213,30.065c4.167,4.21,9.653,6.336,15.139,6.336c5.401,0,10.801-2.041,14.926-6.124l129.276-127.575
        			c4.04-3.997,6.336-9.441,6.336-15.139C512,250.302,509.725,244.88,505.664,240.861z"/></g></g>
        </svg>
      }
      arrow={<RightOutline />}>{t("headers.logout")}</List.Item></Link>
    {!isEmpty(currentHotel) && [
      {
        id: "8item",
        title: t("currencyAnl"),
        value: currentHotel.currency_analytics
      },
      {
        id: "9item",
        title: t("currencyCln"),
        value: currentHotel.currency_calendar
      },
    ].map((item) => (
      <List.Item key={item.id} prefix={<IconCurrency />}>
        <div className="menu-currency-wrapper">
          <span className="menu-currency-title">{item.title}</span>
          <span className="menu-currency">{item.value || t("n_a")}</span>
        </div>
      </List.Item>
    ))}
  </List>);

  return (<div className="menu">
    <NavBar
      mode="light"
      left={<p>{props.title}</p>}
      right={<>
        {props.filterValues && <Popover
          mask
          visible={showFilter}
          position="bottom-end"
          content={
            props.filterValues.map((filterItem, ind) => (
              (<List.Item
                key={`${ind}f`}
                onClick={() => {
                  setShowFilter(false);
                  props.setFilter(filterItem.value);
                }}>
                <Checkbox checked={props.currentFilter === filterItem.value}>{filterItem.title}</Checkbox>
              </List.Item>)
            ))}
           align={{ offset: [0, 15] }}
           trigger="click"
           onVisibleChange={(v) => setShowFilter(v)}>
            <img
              className="menu-filter"
              src={IconFilter}
              alt="Filter"
             />
          </Popover>}

          <button onClick={() => setIsOpen(true)} className="invisible-button">
            <img
              className="menu-icon"
              src={IconOpenMenu}
              alt={"Open menu icon"}/>
          </button>
      </>}>{" "}
    </NavBar>

    <Popup
      className="main-popup"
      position="right"
      bodyStyle={{ paddingTop: 12 }}
      visible={isOpen}
      onClose={() => setIsOpen(prevIsOpen => !prevIsOpen)}>
     {sidebar}
    </Popup>
  </div>
  );
}

export default Menu;
