import React from "react";
import classNames from "classnames";
import uniqid from "uniqid";
import { withTranslation } from "react-i18next";
import Flask from  "./../../img/flask_animate.svg";
import "./style.css";

const CustomSpinner = (props) => {
  const { t, width, size, withText, height } = props;

  return withText ? <div className="spinner-container-full">
  <div className="spinner" style={width ? { width } : {}}>
    <img width={size || "10px"} src={Flask} alt="loading" />
  </div>
  <div className="spinner-letter-container">{t("loadingData").split("").map((letter, ind) =>
      <div
        key={uniqid()}
        className={classNames({
          "spinner-letter": true,
          [`spinner-letter_${ind + 1}`]: true,
          "spinner-letter-whitespace": t("loadingData").split(" ")[0].length - 1 === ind
        })}>{letter}</div>)}</div>
  </div>
  :
  <div className="spinner-container" style={height ? { height } : {}}>
  <div className="spinner" style={width ? { width } : {}}>
    <img width={size || "10px"} src={Flask} alt="loading" />
  </div>
  </div>
}

export default withTranslation()(CustomSpinner);
