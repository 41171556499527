import { createSlice } from "@reduxjs/toolkit";
import { hotelsApi } from "./api/hotelsApi";
import { userApi } from "./api/userApi";

const initialState = {
  hotelInfo: {},
  user: {}
};

export const currentSlice = createSlice({
  name: "current",
  initialState,
  reducers: {
    resetHotel: (state) => {
      state.hotelInfo = {};
    },
    setHotel: (state, action) => {
      state.hotelInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(hotelsApi.endpoints.getHotels.matchFulfilled, (state, action) => {
      const storageHotelId = localStorage.getItem("hotelId");
      const data = action.payload || [];
      const verifiedHotel = data.find((hotel) => hotel.id === storageHotelId);    
      const currentHotel = verifiedHotel || data?.[0] || {};
    
      state.hotelInfo = currentHotel;
    });
    builder.addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

// Export actions
export const { resetHotel, setHotel } = currentSlice.actions;

// Select state currentHotel/currentUser from slice
export const hotelInfo = (state) => state.current.hotelInfo;
export const hotelId = (state) => state.current.hotelInfo.id;
export const hotelIntegrations = (state) => state.current.hotelInfo.integrations;
export const userName = (state) => `${state.current.user.first_name || ""} ${state.current.user.last_name || ""}`;
export const userInfo = (state) => state.current.user;

// Export reducer
export default currentSlice.reducer;