const en = {
  loadingData: "Loading data...",
  login: "Login",
  emailOrUsername: "Email or username",
  password: "Password",
  forgotPass: "Forgot your password?",
  loginIncorrect: "Login or password is incorrect",
  signin: "Sign In",
  firstInHL: "Is it your first time in Hotellab?",
  reg: "Register",
  privPolicy: "Privacy policy",
  contactUs: "Contact us",
  currHotel: "Current hotel",
  currencyAnl: "Analytics currency",
  currencyCln: "Calendar currency",
  n_a: "N/A",

  headers: {
    dashboard: "Dashboard",
    calendar: "Optimization. Prices",
    logout: "Logout",
    fullVers: "Follow to the full version"
  },

  filters: {
    by_date: "same time (date)",
    by_dow: "same time (weekday)",
    on_final: "final (date)",
    by_dow_on_final: "final (weekday)",
  },
  rev: "Revenue",
  load: "Occupancy",
  vs: "vs.",
  forYear: "year",

  breakfastIncluded: "Breakfast",
  breakfastWithout: "Room Only",
  halfBoard: "Half Board",
  fullBoard: "Full Board",
  return: "Refundable",
  unreturn: "Non-refundable",
  minimum: "Minimum",

  source: "Source",
  rate: "Rate",
  numOfPers: "Number of persons",
  updateRates: "Update rates",
  updateRatesForAll: "Update rates for all categories",
  updatedRates: "Rates have been successfully updated",
  notConf: "Automated rate updates are not configured",
  demIndex: "Demand index",
  yourPrice: "Your rate",
  medianChange: "Median change",
  sinceYstd: "(since yesterday)",
  medianCompetitors: "Competitors median",
  salesRates: "Sales rates",

  days: "days"
}

export default en;
